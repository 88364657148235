import React from 'react';
import { Button } from '../LinkButton/LinkButton';
import { Progress } from '../Progress/Progress';


export default function SectionTwo() {
    const mintLink ="https://wonderlandmeta.world/pets"  + window.location.search  ;//'https://viplist.typeform.com/wonder?utm_source=website' ;// 'https://mint.wondergamers.club/' + window.location.search ;
    const minLinkText = "Mint Your Artificially Intelligent Pet";// 'JOIN WHITELIST'
    return (
        <>
            <div id='claim' className='section-2 primary-gradient wf-section' style={{ backgroundImage: `url('./11c9b69137197e19e732d3ae_bg_pattern.png'), linear-gradient(62deg,#eda01f,#f7dc4d)` }}>
                <div className='container w-container'>
                    <div className='grid-2'>
                        <div className='div-block-8'>
                            <div className='div-block-6'>
                                <h2 className='heading h1'>Claim Your Avatar</h2>
                                <div className='div-block-7'>
                                    <p className='paragraph'>
                                        <strong>White Rabbits</strong> and <strong>Mad Hatters </strong>
                                        will soon be available for minting. These are the highly desirable foundations of
                                        the WonderLand Metaverse.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className='div-block-9'>
                                    <h5 className='h6'>10,000 To Mint</h5>
                                    <h6>1200 Pre-Sale Citizens SOLD OUT. Whitelist mint coming soon</h6>
                                    <div className='progress-group'>
                                        <Progress progressWidth={'100'} />
                                        <Progress progressWidth={'0'} />
                                        <Progress progressWidth={'0'} />
                                        <Progress progressWidth={'0'} />
                                        <Progress progressWidth={'0'} />
                                    </div>
                                </div>
                                <a className='btn btn-secondary w-inline-block'  target={`_self`} href={mintLink}><div>{minLinkText}</div></a>
                            </div>
                        </div>
                        <div>
                            <img src={'./sequence-01.gif'} alt='' className='image-5' />
                        </div>
                    </div>
                </div>

                <div className='shape shape-7'>
                    <img src={'./61c99e539ca45643ff9cbb93_4.png'} alt='' />
                </div>
                <div className='shape shape-8'>
                    <img src={'./61c99ee16125314b246f7455_6.png'} alt='' />
                </div>
            </div>
        </>
    )
}
