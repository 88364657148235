import React from 'react'
import BrandCards from '../BrandCard/BrandCard'
import BrandCardsLink from '../BrandCard/BrandCardLink'

export default function Brands() {
    return (
        <>
            <div className='w-layout-grid brands'>
                <BrandCardsLink
                    BrandURL='https://www.bluechain.capital/home52049185'
                    extraClass = 'lg-top-left'
                    imagePath = './brand-1.png' />
                <BrandCards
                    extraClass = 'sm-top-right'
                    imagePath = './brand-2.png' />
                <BrandCards
                    imagePath = './brand-3.png' />
                <BrandCards
                    extraClass = 'lg-top-right'
                    imagePath = './brand-4.png' />
                <BrandCards
                    imagePath = './brand-5.png' />
                <BrandCards
                    imagePath = './brand-6.png' />
                <BrandCards
                    imagePath = './brand-new-1.png' />
                <BrandCards
                    imagePath = './brand-7.png' />
                    
                <BrandCards
                    extraClass = 'lg-bottom-left'
                    imagePath = './brand-10.png' />
                    <BrandCards
                    imagePath = './brand-UNITY.png' />
                <BrandCards
                    extraClass = 'sm-bottom-left'
                    imagePath = './brand-11.png' />
                <BrandCards
                    extraClass = 'lg-bottom-right'
                    imagePath = './brand-new-3.png' />
            </div>
        </>
    )
}
