import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../LinkButton/LinkButton'

export default function Footer() {
    const mintLink = "https://wonderlandmeta.world/pets"  + window.location.search  ;//'https://viplist.typeform.com/wonder?utm_source=website' ;// 'https://mint.wondergamers.club/' + window.location.search ;
    const minLinkText ="Mint Your Artificially Intelligent Pet" ; // 'JOIN WHITELIST'
    return (
        <>
            <footer className='footer'>
                <div id='w-node-f7124c28-2fb4-5bc4-712a-b2c51d0b11c6-97b767ee' className='inline-links'>
                </div>
                <div id='w-node-_7963e9da-169e-51c2-2d0f-5f5c88a12622-97b767ee' className='footer-center'>
                    <img src={'./WonderLandLogo.png'} alt=''  />
                    <a className='btn btn-primary w-inline-block' href={mintLink}  target={`_self`}><div>{minLinkText}</div></a>
                </div>
                <div id='w-node-_2f29f2fd-5552-7179-86f6-1efdad6b11f5-97b767ee' className='social-icons'>
                    <a target={`_blank`}  href='https://discord.gg/zfkPDKbW7w' className='social-item w-inline-block'>
                        <img src={'./61c9b03061253113136fc455_Group 227.png'} alt='' />
                    </a>
                    <a target={`_blank`}  href='https://www.instagram.com/wonderland_meta/' className='social-item w-inline-block'>
                        <img src={'./instagram.png'} alt='' />
                    </a>
                    <a target={`_blank`}  href='https://twitter.com/WonderLand_Meta' className='social-item w-inline-block'>
                        <img src={'./61c9b0308041432c4c009744_Group 225.png'} alt='' />
                    </a>
                    <a target={`_blank`}  href='https://t.me/wondergame_meta/' className='social-item last w-inline-block'>
                        <img src={'./61c9b03083c49a74024a05bb_Group 226.png'} alt='' />
                    </a>
                </div>
            </footer>
        </>
    )
}
