import React from 'react';


export default function Hero() {
    var pauseVideo = function () {
        var videos = document.querySelectorAll('iframe, video');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.tagName.toLowerCase() === 'video') {
                video.pause();
            } else {
                var src = video.src;
                video.src = src;
            }
        });
    };
    
    return (
        <>
            <header id='hero-overlay' className='hero-overlay wf-section'>
                <div className='video-bg' >
                    
                </div>
                <div className='centered-container w-container'>
                    <img src={'./WonderLandLogo.png'} alt='' className='WonderLandLogo' />
                    <div className='div-block-2'>
                        <img src={'./61c96fac0afbca64e712ba28_Group 240.png'} alt='' />
                    </div>
                    <div className='div-block-12'>
                        <button className='btn btn-primary w-inline-block'  onClick={(e) => {document.querySelector('body').classList.add('overflow-hidden'); document.querySelector('.modal').classList.add('show');}}>
                            <img className='btn-icon-img' src='./play-fill.png' alt='' />
                            WATCH TRAILER
                        </button>
                    </div>
                </div>
            </header>
            <div className='modal'>
                <div className='container w-container'>
                    <div style={{padding:`5% 0 0 0`, position:`relative`}}>
                        <iframe className='video' src='https://player.vimeo.com/video/683223581?h=19a6c60194&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullscreen 
                        style={{position:`absolute`, top:`0`, left:`0`, width:`100%`, height:`100%`}} title="WonderGame Metaverse NFT"></iframe></div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                    <button className='close-btn'  onClick={(e) => {document.querySelector('body').classList.remove('overflow-hidden'); document.querySelector('.modal').classList.remove('show'); pauseVideo();}}>
                        <img src='./close.png' alt='' />
                    </button>
                </div>
            </div>
            <div className="headerNotifications">
                <h4><a href='./pets'>1ST & 2ND CITIZEN MINTS SOLD OUT.&nbsp;&nbsp;&nbsp;&nbsp; MINT PETS NOW.</a></h4>
                {/* <h4><a href='./land/map'>1ST & 2ND CITIZEN MINTS SOLD OUT.&nbsp;&nbsp;&nbsp;&nbsp; JOIN THE LAND SALE NOW.</a></h4> */}
                {/* <h4><a href='./mint'>First Sale: 1223/1223 SOLD OUT! Limited Sale: Jan 28. JOIN NOW</a></h4> */}
                {/* <h4><a href='./mint'>CITIZENS NEARLY SOLD OUT</a></h4> */}
            </div>
        </>
    )
}
