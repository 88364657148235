import React from 'react';
import Brands from '../Brands/Brands';
import { Card } from '../Card/Card';
import Footer from '../Footer/Footer';
import { Button } from '../LinkButton/LinkButton';
import Timeline from '../Timeline/Timeline';

// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


export default function SectionThree() {
    const mintLink ='https://wonderlandmeta.world/land/map' + window.location.search;// 'https://mint.wondergamers.club/' + window.location.search ;
    const minLinkText = 'Buy Land'
    return (
        <>
            <div className='section-3 secondary-gradient wf-section'>
                <div className='container w-container' id='investors-and-partners'>
                    <h2 className='heading heading-white h1 text-center'>INVESTORS & PARTNERS</h2>
                    <Brands />
                </div>

                
                <div className='our-team'>
                    <h2 className='heading heading-white h1 text-center'>our team</h2>
                    <div className='slider-wrapper'>
                        <Swiper
                            // install Swiper modules
                            slidesPerView={"auto"}
                            loop={true}
                            modules={[ Autoplay, Navigation]}
                            centeredSlides={true}
                            spaceBetween={10}
                            navigation={true}
                            scrollbar={{ draggable: true }}
                            className="teamSlider"
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                768: {
                                    centeredSlides: false,
                                    spaceBetween: 30,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cc87cbb46eb7d5e744c87_image 15.jpg' alt='' className='image-6' />
                                        <h4>Crypto Queen</h4>
                                        <div className='text-block-3'>Co-ruler of 3 Crypto Kingdoms, leading the DeFi revolution inside the Metaverse.</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>Crypto Queen</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Virtual Reality, Augmented Reality, AI and 3D Metaverse
                                                    expert.</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Rainmaker building &amp; leading high performing  Software Teams.</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Co Founder of DeFi Projects</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>HODLR.</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd01a56149b4a3a4ef0fe_image 16.jpg' alt='' className='image-6' />
                                        <h4>DeFi Prince</h4>
                                        <div className='text-block-3'>Highly successful Entrepreneur and CEO, with 3 successful founded companies, 6 acquisitions, 3 exits. DeFi educator &amp; DEX Founder.</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>DeFi Prince</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Professional Robotics Engineer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Leader of the Metaverse revolution</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Co Founder of DeFi Projects</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Award Winning Viral Marketer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>HODLR</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0d89334a37c6a5d252e_5-01 1.jpg' alt='' className='image-6' />
                                        <h4>DeFi God</h4>
                                        <div className='text-block-3'>Senior Smart Contract Blockchain Developer and Founder of  DeFi Kingdoms</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>DeFi God</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Doctors of Blockchain (one of the 1st degens to receive their doctors)</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>University Lecturer on Blockchain &amp; DeFi </div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Chainlink experience</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>NFT project Founder</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Solidity Developer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>HODLR</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0ed982fbe0ec681dfd2_9-01 1.jpg' alt='' className='image-6' />
                                        <h4>The Crypto Impaler</h4>
                                        <div className='text-block-3'>Crypto Whale and King of the Bull Run, Leader of Global DeFi Degens</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>The Crypto Impaler</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Founder of multiple Crypto, DeFi and Metaverse projects</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Sought out by Federal Governments for Blockchain expertise</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>DeFi expert</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'> Software Engineer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Crypto Trader</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0ef157ff3856b7d5a8a_rileystewart865_10_REV_1 1.jpg' alt='' className='image-6' />
                                        <h4>Systems Architect</h4>
                                        <div className='text-block-3'>Key Architect of fantastical DeFi and Metaverse Worlds</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>Systems Architect</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Smart Contract Developer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>DeFi Degen</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Full stack developer</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0edd8ed4d5f13e97600_14-01 1.jpg' alt='' className='image-6' />
                                        <h4>Meta Wizard</h4>
                                        <div className='text-block-3'>Co-ruler of 3 Crypto Kingdoms, leading the DeFi revolution inside the Metaverse.</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>Meta Wizard</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Full stack developer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Smart contract Developer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Linear Finance &amp; Convergence Finance experience</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Expertise in DeFi Protocols</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0eed36bc67e5a57855a_image 45.jpg' alt='' className='image-6' />
                                        <h4>Virtual God</h4>
                                        <div className='text-block-3'>Co-ruler of 3 Crypto Kingdoms, leading the DeFi revolution inside the Metaverse.</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>Virtual God</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>3D Metaverse and NFT creator</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Unity Designer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Metaverse game developer</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0ef9981d4c2179ddd01_image%2046.jpg' alt='' className='image-6' />
                                        <h4>NFT Hero</h4>
                                        <div className='text-block-3'>NFT expert with experience across 47 NFT projects</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>NFT Hero</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>World class artist</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Supreme NFT creator</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>3D Metaverse developer</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0ec90681f31291e0176_2-01%201.jpg' alt='' className='image-6' />
                                        <h4>Crypto JKD</h4>
                                        <div className='text-block-3'>Expert in building Crypto Collabs and Metaverse Relationships
                                        </div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>Crypto JKD</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Partnerships manager</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Community Manager</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>DeFi Degen</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>HODLR</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0edd0bb73a7e8f3006c_13-01%201.jpg' alt='' className='image-6' />
                                        <h4>Meta Mooner</h4>
                                        <div className='text-block-3'>Community hype master</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>Meta Mooner</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Virtual Reality Developer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Blockchain native game developer</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='team-card'>
                                    <div className='team-card-body'>
                                        <img src='621cd0ef9334a328865d2625_image%2047.jpg' alt='' className='image-6' />
                                        <h4>Crypto Knight</h4>
                                        <div className='text-block-3'>Skilled Crypto Kingdom Designer</div>
                                    </div>
                                    <label className='btn btn-team w-button' onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>Special Skills</label>
                                    <div className='skill-overlay'>
                                        <h4>Crypto Knight</h4>
                                        <ul className='list w-list-unstyled'>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Senior UI/UX Designer</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>Graphics artist</div>
                                            </li>
                                            <li className='list-item'>
                                                <img src='bullet.svg' alt='' className='list-dot' />
                                                <div className='list-text'>React Developer</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

                <div className='container w-container'>
                    <a href='https://www.assuredefi.io/projects/wondergame-metaverse/' className='added-text'>
                        <div className='added-text-image'>
                            <img src='./assure.png' />
                        </div>
                        <div className='added-text-content'>
                            TEAM HAS BEEN DOXXED WITH KYC APPROVED BY ASSURE DEFI
                        </div>
                    </a>

                    <Card cardStyle={'card-md'} cardMargin={'mb-30'} cardID={'roadmap'}>
                        <h2 className='heading h1 text-center'>ROADMAP</h2>
                        <Timeline />
                        <div className='text-center'>
                            <a className='btn btn-primary w-inline-block'  href={mintLink}><div>{minLinkText}</div></a>
                        </div>
                        <div className='shape shape-12'>
                            <img src={'./61c9b27283c49a07484a1ebf_Group 237.png'} alt='' />
                        </div>
                        <div className='shape shape-13'>
                            <img src={'./61c9b2f8b80ffabdb9b49722_Group 235.png'} alt='' />
                        </div>
                    </Card>

                    <Card cardStyle={'card-lg'} cardMargin={'mb-30 primary-gradient'}>
                        <h2 className='heading h1 text-center'>LONG TERM APPLICATION</h2>
                        <div className='div-block-10'>
                            <p className='paragraph'>
                            Our vision is to create a real-world utility template for Web3 Projects and Enterprise, with community at its core. By continuing to incorporate a wide array of projects into our world, we aim to provide communities with the tools to build and create their own spaces inside the WonderLand Metaverse.<br/><br/>
                            Land can be used to host a wide array of things; a custom realm, business, fanclub, hang-out, Shop, gaming complex, office, or community. DeFi Projects could buy land to integrate with their protocol via a Bank or Shop. NFT projects could build art galleries. Land Owners can host their own education or social events, and even live concerts with your favourite musician.<br/><br/>
                            Businesses can live in the physical world, digital world, or both. Businesses in WonderLand could be types such as Nightclubs, Bars, Vendors, Galleries, Game Hosts, and Stores. Physical-world businesses hosted within the Metaverse could be your favorite National Sports Club, Services Provider, or Merchant. Imagine buying a new in-game NFT Watch for your character, and having the physically-backed item appear at your door to wear in real life… Ooh the delight!<br/><br/>
                            Our Metaverse can host NFT projects, quests, storylines, items, transactions and smart contracts. The goal is to partner with other projects for strategic value to both. We collaborate with important collections, projects or businesses more extensively via dedicated buildings, quests, and community events. Users can explore these areas in 3D and/or VR for a life-like interactive experience. Using partnered CeFi and Enterprise integrations, users will be able to use the map to visit a physical store virtually, and make purchases online using Web3.<br/><br/>
                            As global Metaverse VR technology develops, WonderLand aims to integrate completely into this decentralized world to allow for users to enter the Wonderverse through VR, interact and enter various games, trade, earn, and build their own empire and DeFi wealth.
                            </p>
                        </div>
                    </Card>

                    <Card cardStyle={'card-lg'} cardMargin={'mb-96'} cardID={'faqs'}>
                        <h2 className='heading h1 text-center'>FREQUENTLY ASKED QUESTIONS</h2>
                        <div className='div-block-10'>
                            <div class="accordions">
                                <div class="accordion-item">
                                    <div class="accordion-head" onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>
                                        <div class="accordion-indicator">
                                            <img src='plus.png' className='accordion-indicator-plus' />
                                            <img src='minus.png' className='accordion-indicator-minus' />
                                        </div>
                                        <h3 class="accordion-heading">Mint Details</h3>
                                    </div>
                                    <div class="accordion-body">
                                        <p class="paragraph-3">
                                            <strong>MINT DATE:</strong> Pre-Sale Mint is SOLD OUT. Silver Whitelist Mint details coming soon. Join our socials and whitelist to stay ahead.

                                            <br /><br /><strong>MINT COST:</strong> 0.06 ETH (future mints will be 0.1-0.2 ETH)

                                            <br /><br /><strong>MAX NFT's </strong>PER MINT: 10

                                            <br /><br /><strong>MINT LOCATION:</strong> <a href="https://wonderlandmeta.world/mint">https://wonderlandmeta.world/mint</a>

                                            <br /><br /><strong>MAX SUPPLY THIS MINT:</strong> Only 1500/10,000
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-head" onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>
                                        <div class="accordion-indicator">
                                            <img src='plus.png' className='accordion-indicator-plus' />
                                            <img src='minus.png' className='accordion-indicator-minus' />
                                        </div>
                                        <h3 class="accordion-heading">What is WonderLand?</h3>
                                    </div>
                                    <div class="accordion-body">
                                        <p class="paragraph-3">
                                        WonderLand is a 3D Metaverse NFT project where players can own land… Build kingdoms, trade collectables, host hangouts, game, and more.  Players can even open their own Metaverse Business and make an income. 

                                            <br /><br />We’re building our entire own metaverse from scratch using Unity Engine… Which is the same engine running Sandbox and Decentraland. Our community owns this project, and we have a large enterprise sales team with onramps and partners in 135 countries focussed on onboarding large enterprise whales and their accompanying communities in masses.

                                            <br /><br />Players can browse or open Metaverse stores, showrooms, galleries and hangouts in 3D and VR across our 5 Metaverse Cities in our world. This includes selling NFT products, physically-backed NFT's, and helping them accept crypto as payment.
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-head" onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>
                                        <div class="accordion-indicator">
                                            <img src='plus.png' className='accordion-indicator-plus' />
                                            <img src='minus.png' className='accordion-indicator-minus' />
                                        </div>
                                        <h3 class="accordion-heading">Why choose WonderLand?</h3>
                                    </div>
                                    <div class="accordion-body">
                                        <p class="paragraph-3">
                                        WonderLand is built by world-class artists, game designers, de-gen DeFi developers straight from Chainlink and Convergence Finance… Fortune 500 Leaders, and futurists… all on a mission to make the Open Metaverse a reality and bring about the next stage in Human Evolution.

                                            <br /><br />A valuable advantage we have is our connection into the traditional finance world… We have partnerships and on-ramps in over 135 countries globally giving us the power to mass deliver the red pill and set people free from The Fiat Fathers!

                                            <br /><br />So instead of fighting other metaverse projects for users, we onboard thousands of completely new virgin users into the metaverse.
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-head" onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>
                                        <div class="accordion-indicator">
                                            <img src='plus.png' className='accordion-indicator-plus' />
                                            <img src='minus.png' className='accordion-indicator-minus' />
                                        </div>
                                        <h3 class="accordion-heading">What are the value of the NFTs?</h3>
                                    </div>
                                    <div class="accordion-body">
                                        <p class="paragraph-3">
                                            Besides the epic art, what’s also cool is… each NFT has the ability to earn you passive $WonderGold crypto, the economies core resource.

                                            <br /><br />Each Holder has control over the WonderLand Treasury… A Treasury BACKING the price of each NFT with real assets like Ethereum and blue chip NFT’s including valuable realestate in other Metaverse lands.

                                            <br /><br />A portion of the Treasury is placed into a unique community Decentralized VC Fund to incubate new projects to the moon and increase treasury value.

                                            <br /><br />Each Gold Whitelist Mint comes with a free penthouse inside our own Metaverse built on Unity! Plus, you get access to other metaverse realms and Alpha Communities to network and party.
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-head" onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>
                                        <div class="accordion-indicator">
                                            <img src='plus.png' className='accordion-indicator-plus' />
                                            <img src='minus.png' className='accordion-indicator-minus' />
                                        </div>
                                        <h3 class="accordion-heading">How to buy WonderLand?</h3>
                                    </div>
                                    <div class="accordion-body">
                                        <p class="paragraph-3">
                                            Our initial NFT’s will be on Ethereum and the metadata will be fully stored on IPFS shortly after mint. We have also received a grant from the Polygon team, and we love having their support, so our second chain we implement for game dynamics where low-gas solutions are required will be Polygon. From there, we will expand to even more blockchain networks depending on community voting!
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-head" onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>
                                        <div class="accordion-indicator">
                                            <img src='plus.png' className='accordion-indicator-plus' />
                                            <img src='minus.png' className='accordion-indicator-minus' />
                                        </div>
                                        <h3 class="accordion-heading">How do you positively impact the World?</h3>
                                    </div>
                                    <div class="accordion-body">
                                        <p class="paragraph-3">
                                            After a mint we will let the community decide through which charity to donate and plant 50,000 real trees! This is awesome for a community WonderForest, it costs roughly $1 per tree only.

                                            <br /><br />We are also allocating some treasury income to our Planet Earth Fund, which will focus on beneficial planetary projects.
                                        </p>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <div class="accordion-head" onClick={(e) => {e.currentTarget.classList.contains('active') ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add('active')}}>
                                        <div class="accordion-indicator">
                                            <img src='plus.png' className='accordion-indicator-plus' />
                                            <img src='minus.png' className='accordion-indicator-minus' />
                                        </div>
                                        <h3 class="accordion-heading">Where do I find the best updates for WonderLand?</h3>
                                    </div>
                                    <div class="accordion-body">
                                        <p class="paragraph-3">Come join the <a href='https://discord.com/invite/zfkPDKbW7w' className='faqs-link'>WonderLand community on Discord!</a> And <a href='https://twitter.com/WonderLand_Meta' className='faqs-link'>Twitter!</a> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Footer />
                </div>

                <div className='shape shape-9'>
                    <img src={'./61c9b142f0e447e66583658e_20.png'} alt='' />
                </div>
                <div className='shape shape-15'>
                    <img src={'./61c99e539ca45643ff9cbb93_4.png'} alt='' />
                </div>
                <div className='shape shape-10'>
                    <img src={'./61c9b1990344d15525deaafb_Mask Group (3).png'} alt='' />
                </div>
                <div className='shape shape-11'>
                    <img src={'./61c9b1eb1e48c40c2f1a1d90_Group 1158.png'} alt='' />
                </div>
                <div className='shape shape-14'>
                    <img src={'./61c9b3769ca45660d89d6507_Group 233.png'} alt='' className='image-3' />
                </div>
            </div>
        </>
    )
}
