import React from 'react';
import { Card } from '../Card/Card';
import { Button } from '../LinkButton/LinkButton';


export default function SectionOne() {
    const mintLink ='https://wonderlandmeta.world/land/map' + window.location.search ;// 'https://mint.wondergamers.club/' + window.location.search ;
    const minLinkText = 'Buy Land'
    return (
        <>
            <div className='section-1 secondary-gradient wf-section'>
                <div className='container w-container'>
                    <Card cardStyle={'card-lg'} cardMargin={'mb-30'}>
                        <div className='shape shape-2'>
                            <img src={'./61c9958446ef2d5ffc9390f2_Group 238.png'} alt='' />
                        </div>
                        <div className='shape shape-1'>
                            <img src={'./61c994fd31d89b51970d33dc_Group 205.png'} alt='' />
                        </div>
                        <h1 className='heading h1'>WELCOME TO THE WONDERLAND METAVERSE</h1>
                        <div className='div-block-3'>
                            <p className='paragraph'>
                            WonderLand a 3D VR Metaverse where players can own land, build kingdoms, trade, play games, hangout, and shop. For Enterprise, WonderLand is becoming the "Shopify of the Metaverse" with a simple 3 step process to enter and begin monetizing. Thousands of Citizens live and play on an island in WonderLand, their home in the Metaverse.<br/><br/>
                            The project's aim is to build the underlying infrastructure every metaverse and enterprise needs with real-world utility, and to do so while building a powerful metaverse from scratch.<br/><br/>
                            The Citizens of WonderLand are White Rabbits and Mad Hatters, governing our community DAO, investment treasury, and profits. A tempting prize of $WG awaits, with multiple play to earn and utility functions. Mad Hatters are the Alpha Citizen; helping to govern our non-citizen visitors and projects entering our metaverse.<br/><br/>
                            Land can be purchased, with different characteristics and locations available for each. A User, Project, or Enterprise can purchase their own Web3 land to build and host their own metaverse kingdom, gallery, games, lounges, stores, or anything else their heart desires. Unlike other metaverse projects, WonderLand has native DeFi and Crypto integrations enhancing its power.<br/><br/>
                            WonderLand is built by experts from DeFi, Tech Startup CEO’s, Virtual Reality and 3D gaming, Metaverse Architects and Artists, and Degen Futurists… All on a mission to make the open metaverse a reality and bring about the next stage in human evolution.<br/><br/>
                            Join us now and take part in building our future.
                            </p>
                        </div>
                        <a className='btn btn-primary w-inline-block'  href={mintLink}><div>{minLinkText}</div></a>
                    </Card>

                    <div className="grid-1">
                        <Card cardMargin={''}>
                            <div className="card-img">
                                <img src="./land-1.png" alt="" className="image-4" />
                            </div>
                            <div className="card-content">
                                <h3 className="heading">Land</h3>
                                <div className="div-block-5">
                                    <p className="sub-heading">Build Your Empire, Prosper Off The Metaverse.</p>
                                </div>
                                <p className="paragraph">
                                    A finite resource, only 10,000 genesis land plots will ever exist. Land
                                    owners will be able to host their own hangouts, businesses, farming operations, or lease
                                    the space out for rental yield. Plots have varying traits, cultivate them on your quest
                                    for economic dominance.
                                </p>
                            </div>
                        </Card>
                        <Card cardMargin={''}>
                            <div className="card-img">
                                <img src="./info-2.png" alt="" className="image-4" />
                            </div>
                            <div className="card-content">
                                <h3 className="heading">WHITE RABBITS</h3>
                                <div className="div-block-5">
                                    <p className="sub-heading">Farm Precious WonderLand Gold. Expand Your Resources</p>
                                </div>
                                <p className="paragraph">
                                As an owner of WonderLands backbone, the White Rabbits, you control the farming of precious Gold and its flow through the  economy. White Rabbits can be deployed to farm $WG and set free on prosperous Land to increase APY’s.
                                </p>
                            </div>
                        </Card>
                        <Card cardMargin={''}>
                            <div className="card-img">
                                <img src="./info-3.png" alt="" className="image-4" />
                            </div>
                            <div className="card-content">
                                <h3 className="heading">MAD HATTERS</h3>
                                <div className="div-block-5">
                                    <p className="sub-heading">Steal. Pillage. Make Bank. Wonderland Is Yours For The Taking.</p>
                                </div>
                                <p className="paragraph">
                                The most profitable actors in WonderLand, Mad Hatters are unpredictably MAD. They steal resources from White Rabbits and Farmers and are impossible to catch. Build your collection to become an economic force to reckon with.
                                </p>
                            </div>
                        </Card>
                        <Card cardMargin={''}>
                            <div className="card-img">
                                <img src="./info-4.png" alt="" className="image-4" />
                            </div>
                            <div className="card-content">
                                <h3 className="heading">$WG</h3>
                                <div className="div-block-5">
                                    <p className="sub-heading">Farm. Profit. Prosper.</p>
                                </div>
                                <p className="paragraph">
                                Everyone in WonderLand needs more WonderGOLD! The utility and governance token of the metaverse, it’s the most precious resource you can obtain. Use it to develop your farm, go shopping, build your empire, and rule.
                                </p>
                            </div>
                        </Card>
                    </div>
                </div>

                <div className="shape shape-3">
                    <img src={'./61c996f25953581d7550276f_18.png'} alt='' />
                </div>
                <div className="shape shape-4">
                    <img src={'./61c997985f39294a1cbe4217_Mask Group.png'} alt='' />
                </div>
                <div className="shape shape-5">
                    <img src={'./61c998eacad55ff3123d85bf_Mask Group (1).png'} alt='' />
                </div>
                <div className="shape shape-6">
                    <img src={'./61c999116637243954f5b47d_Mask Group (2).png'} alt='' />
                </div>
            </div>
        </>
    )
}
