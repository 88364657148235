import React from 'react'
import TimelineItem from '../TimelineItem/TimelineItem'

function Timeline() {
    return (
        <>
            <div className='timeline'>
                <TimelineItem 
                    margin='ml-auto'
                    indicatorNum='1'
                    indicatorPosition='timeline-item-indicator-left'
                    title='JOIN THE WONDERLAND!'
                    description='10,000 Wonderland NFTS will be released for minting. Claim your unique White Rabbit, or try your luck randomly minting a rare Mad Hatter. Drawn by some of the blockchain’s most expensive NFT artists, these are sought after collectors items.' />
                <TimelineItem 
                    margin='mr-auto'
                    indicatorNum='2'
                    indicatorPosition='timeline-item-indicator-right'
                    title='EXCLUSIVE GIVEAWAYS'
                    description='When 10% of the NFT’s are sold, 10 Lucky Holders will get a FREE RABBIT + 5 Lucky Holders will get $1500 ETH each!' />
                <TimelineItem 
                    margin='ml-auto'
                    indicatorNum='3'
                    indicatorPosition='timeline-item-indicator-left'
                    title='SWAG'
                    description='When 25% of the NFT’s are Sold, 20 Lucky Holders will win a free WonderLand shirt with their NFT on it... sent to their door! (Remember Unisocks? They had a similar feature and the floor price for NFT Socks is now $98,000!)' />
                <TimelineItem 
                    margin='mr-auto'
                    indicatorNum='4'
                    indicatorPosition='timeline-item-indicator-right'
                    title='DONATION'
                    description='When all the NFT’s are minted, we will donate $50,000 to a Community selected Charity to plant 50,000 trees and build the world’s first WonderForest! 🌳' />
                <TimelineItem 
                    margin='ml-auto'
                    indicatorNum='5'
                    indicatorPosition='timeline-item-indicator-left'
                    title='PLAY'
                    description='The WonderLand begins. Thousands of White Rabbits and Mad Hatters compete to earn crypto. A tempting prize of $WG awaits, with deadly high stakes.' />
                <TimelineItem 
                    margin='mr-auto'
                    indicatorNum='6'
                    indicatorPosition='timeline-item-indicator-right'
                    title='FARM'
                    description='10,000 plots of Genesis Metaverse Land will be available for minting, with randomly generated traits available. Land will enhance the farming APY’s of White Rabbits AND be used to host community games, hangouts, and businesses.' />
                <TimelineItem 
                    margin='ml-auto'
                    indicatorNum='7'
                    indicatorPosition='timeline-item-indicator-left'
                    title='WIN IN THE WONDERLAND ECONOMY'
                    description='WonderLand has secured exciting partnerships with large DeFi platforms, NFT projects, and even public listed companies from the “real-world” wanting a piece of the metaverse. These soon-to-be-released partnerships combined with our multi-million dollar marketing budget is virtually guaranteed to create a thriving in-demand economy… Where early adopters will thrive!' />
                <TimelineItem 
                    margin='mr-auto'
                    indicatorNum='8'
                    indicatorPosition='timeline-item-indicator-right'
                    title='RELEASE THE WONDERLAND!'
                    description='When 100% of land and accompanying NFT’s are sold, we will begin developing our avatar-based immersive metaverse game. WonderLand NFT Holders will be able to walk around the metaverse and interact with people, games, businesses and the community. People will love it so much they will never want to leave.' />
            </div>
        </>
    )
}

export default Timeline