import React from 'react'

export default function BrandCards(props) {
    return (
        <>
            <div className={`brand-card ${props.extraClass}`}>
                <img src={props.imagePath}  alt='' />
            </div>
        </>
    )
}
