import React from 'react'

export default function BrandCardsLink(props) {
    return (
        <>
            <div className={`brand-card ${props.extraClass}`}>
                <a href={props.BrandURL} target={`_blank`}><img src={props.imagePath}  alt='' /></a>
            </div>
        </>
    )
}
