import React, { useState } from 'react';
import { Link } from 'react-scroll';
//import { Button } from '../LinkButton/LinkButton';

function Navbar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click); 
    const closeMobileMenu = () => setClick(false);
    const mintLinksea = 'https://wonderlandmeta.world/pets';// + window.location.search ;
    // const mintLinksea = 'https://wonderlandmeta.world/mint';// + window.location.search ;
    const qs=window.location.search;
    return (
        <> 
            <div className='navbar secondary-gradient w-nav' data-collapse="medium">
                <div className='navbar-container container-2 w-container'>
                    <Link to='hero-overlay' spy={true} smooth={true} offset={0} duration={500} className='brand w-nav-brand'>
                        <img src={'./wonderland_text.png'} alt='' className='navbarLogo' />
                    </Link>
                    <nav className={click ? 'nav-menu w-nav-menu w--open' : 'nav-menu w-nav-menu'}>
                        <Link hre to='roadmap' spy={true} smooth={true} offset={0} duration={500} className='nav-link w-nav-link' onClick={closeMobileMenu}>Roadmap</Link>
                        <a href={'/land/usecases' + qs}  className='nav-link w-nav-link' onClick={closeMobileMenu}>Use Cases</a>
                        <a href={'/land/Monetization' + qs}   className='nav-link w-nav-link' onClick={closeMobileMenu}>Monetization</a>
                        <a href={'/land/Contact' + qs}   className='nav-link w-nav-link' onClick={closeMobileMenu}>Contact Us</a>
                        <a href={'/meta/penthouse' + qs} target='_blank' className='nav-link w-nav-link ' onClick={closeMobileMenu}>Enter Metaverse</a>
                        <div className='div-block-1105'>
                            <a href={mintLinksea + qs} className='btn btn-primary w-inline-block' target={`_self`}>
                                <div>
                                <img src='buylandbadges-2.png' className='buyLandBadges' />Buy Land
                                </div>
                            </a>
                        </div>
                    </nav>
                    <div className='menu-button w-nav-button' onClick={handleClick}>
                        <img src={click ? './group_1157.png' : './group_1143.png'} alt='' />
                    </div>
                    <div className='div-block-11'>
                        <a   href={mintLinksea + qs} className='btn btn-primary w-inline-block navlinkBuyLand' target={`_self`}>
                            <div >
                             {/*<img src='buylandbadges-2.png' className='buyLandBadges' />Buy Land */}
                            MINT PETS
                            </div>
                            
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
