import React from 'react'
import useInView from 'react-cool-inview'

function TimelineItem(props) {
    const {observe, inView, scrollDirection } = useInView({
        threshold: 0.25, // Default is 0
        onChange: ({ scrollDirection }) => {
            if(scrollDirection.vertical === 'up') {
                return false;
            } else {
                return true;
            }
        },
    });

    return (
        <>
            <div className={`timeline-section ${(inView || scrollDirection.vertical === 'up') ? 'visible' : '' }`} ref={observe}>
                <div className={`timeline-item ${ props.margin }`}>
                    <h5 className='heading h5'>{ props.title }</h5>
                    <p className='paragraph'>{ props.description }</p>
                    <div className='timeline-progress'>
                        <div className='timeline-progress-bar'></div>
                    </div>
                    <div className={`timeline-item-indicator ${ props.indicatorPosition } ${ props.indicatorStatus }`}>
                        <div className='timeline-item-indicator-inner'>{ props.indicatorNum }</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimelineItem